import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <p>
                © 2025 <a href="https://arman.ninja/" target="_blank" rel="noopener noreferrer">arman.ninja</a>. All rights reserved.
            </p>
        </footer>
    );
};

export default Footer;
